import { Box } from "@mui/material";
import Content from "./Content";
import Demo from "./Demo";

const Home = () => {
  return (
    <Box sx={{ pb: 16, pt: 8 }}>
      <Content />
      <Demo />
    </Box>
  );
};
export default Home;
