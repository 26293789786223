import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import YouTube from "react-youtube";

const Demo = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1440/730",
          paddingBottom: "56.25%",
          position: "relative",
          transition: "all .3s",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            transform: "scale(1.01)",
          },
        }}
        onClick={handleOpen}
      >
        <img
          src="thumbnail.png"
          alt="Demo Video Thumbnail"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            borderRadius: 20,
            cursor: "pointer",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            backgroundColor: "#181930",
            cursor: "pointer",
            borderRadius: "100%",
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
            width: "5rem",
            height: "5rem",
            transition: "all .3s",
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0 .25rem 2rem .5rem rgba(0, 0, 0, .2)",
            "&:hover": {
              transform: "translate(-50%, -50%) scale(.95)",
            },
          }}
        >
          <img
            src="https://cdn.prod.website-files.com/65b8bbccfce037ef4296faaa/65b8f27f6896cab9b091e679_Play--filled--alt.svg"
            loading="lazy"
            alt="play icon"
            class="hero-icon"
          ></img>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "80%",
            position: "relative",
            backgroundColor: "#000",
            outline: "none",
          }}
        >
          <YouTube
            videoId="O9R5M70UH3E"
            opts={options}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Demo;
