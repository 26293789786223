import React from "react";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
  Box,
} from "@mui/material";
import theme from "../theme";
import BlogPostImg from "../assets/BlogPostImg.png";

const BlogContent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container>
      <Paper
        elevation={0}
        sx={{ paddingX: isMobile ? "0" : "120px", marginTop: "20px" }}
      >
        <Typography variant="body1" gutterBottom>
          <strong>Disclaimer:</strong> If you're in a strategic position at a
          business, this blog might be very relevant for you!
        </Typography>
        <Divider sx={{ marginY: 2 }} />

        <Typography variant="body1" paragraph sx={{ marginTop: 5 }}>
          Hello and Welcome !
        </Typography>

        <Typography variant="body1" paragraph>
          This is the first blog in our series, where we pen down some thoughts
          and highlight the technology we choose to build up in. This field of
          science has been there since 1940s and has witnessed multiple
          breakthroughs but has never truly captured the spotlight. Our effort
          to contribute in this field is a sincere and ambitious attempt towards
          celebrating its novelty.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          Decision Intelligence
        </Typography>

        <Typography variant="body1" paragraph>
          You run a business. Over the years, you have collected a lot of data
          and want to make the best use of it. The data gives you information,
          you apply analytics on that information to gain insights. And how you
          go from insights to actionable strategy for driving actual business
          value is this process called Decision Intelligence{" "}
          <strong>(DI)</strong>.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 3 }}
        >
          A general framework for the application of DI
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="A. You know what you want to achieve, your business objectives or the KPIs you care for." />
          </ListItem>
          <ListItem>
            <ListItemText primary="B. You also know your business limitations, resource constraints or budget restrictions." />
          </ListItem>
          <ListItem>
            <ListItemText primary="C. You have the information and insights at hand." />
          </ListItem>
        </List>
        <Typography paragraph>
          DI tells you, what’s the best way to achieve <strong>A</strong>, given{" "}
          <strong>C</strong>, while respecting <strong>B</strong>.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          The spectrum of DI spans across three levels
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Decision Support"
              secondary={
                <Typography fontSize="1rem">
                  Assists human decision-making with analytics and data
                  exploration. For example, using sales dashboards to decide
                  which product to scale.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Decision Augmentation"
              secondary={
                <Typography fontSize="1rem">
                  Recommends decisions to be made and humans choose what seems
                  the best choice. For example, generating optimal sports league
                  schedules for maximizing fairness.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Decision Automation"
              secondary={
                <Typography fontSize="1rem">
                  Enables the machines to perform both the decision step and the
                  execution step autonomously. For example, algorithmic trading
                  systems in finance that buy and sell stocks.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 2 }}
        >
          The Technology
        </Typography>

        <Typography paragraph>
          With more and more data, the world is becoming increasingly complex
          each passing year. So the process of decision making has become more
          complex owing to dealing with more variables. And that calls for
          bringing in technology to help your business make informative and
          intelligent decisions. Sometimes (mostly) decision making problems can
          be hard, in fact so hard that they are NP-Hard, meaning no efficient
          solution is known even for computers. Fortunately, we have some
          friends to help tackle these complexities. Several fields contribute
          to the study of Decision Intelligence, including mathematical
          optimization, operations research, complexity theory, heuristics, and
          AI. Ever heard of the Travelling Salesman Problem (TSP), ask any
          competitive programmer and you will hear it cannot be solved for more
          than 20 locations. But these technologies use it as a toy problem,
          routinely solving TSP optimally for 1000 locations. Decision
          Intelligence is a very powerful technology with a lot of literature on
          the complex problems businesses around the world face today in their
          operations.
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          What are Some Business Applications of DI
        </Typography>

        <TableContainer component={Paper} sx={{ marginY: 2 }}>
          <Table aria-label="decision intelligence use cases">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Use Case</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Description</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Example</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Workforce Scheduling
                  </Typography>
                </TableCell>
                <TableCell>
                  Assigning employees to shifts and tasks, balancing various
                  constraints to maximize efficiency.
                </TableCell>
                <TableCell>Shift assignment in hospitals</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Facility Location
                  </Typography>
                </TableCell>
                <TableCell>
                  Determining strategic locations for facilities to minimize
                  costs and maximize service efficiency.
                </TableCell>
                <TableCell>Planning an EV charging stations network</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Server Allocation
                  </Typography>
                </TableCell>
                <TableCell>
                  Allocating computing resources to tasks to maximize
                  performance and minimize costs.
                </TableCell>
                <TableCell>AWS cost optimization</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight="bold">
                    Portfolio Optimization
                  </Typography>
                </TableCell>
                <TableCell>
                  Selecting the best mix of assets to maximize returns while
                  managing risk.
                </TableCell>
                <TableCell>
                  Trading strategies in different market segments
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="body1" gutterBottom sx={{ marginTop: 4 }}>
          <strong>Answers to all your how/which/what:</strong>
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Resource Allocation"
              secondary={
                <Typography fontSize="1rem">
                  How many groundwork people to hire in which city, how much
                  raise to give to which employee, how much loan to give to
                  which people.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Optimal Matching"
              secondary={
                <Typography fontSize="1rem">
                  Which ad to show to which user, which dating profile to show
                  to which person, which ride request to show to which driver.
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Strategy Making"
              secondary={
                <Typography fontSize="1rem">
                  What is the best online marketing strategy, what is the best
                  hiring strategy, what is the best dynamic pricing strategy.
                </Typography>
              }
            />
          </ListItem>
        </List>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 2 }}
        >
          Present and Future Potential
        </Typography>

        <Typography paragraph>
          The quality of decision making at scale is poised to become the
          differentiator of businesses within the same industry. For instance,
          take Blinkit’s vs Zepto’s approach for decisions at all scales in
          quick commerce. From low frequency decisions like dark store placement
          to mid frequency like inventory management to high frequency like
          delivery executive assignment, their strategic decisions ultimately
          guide their service levels.
        </Typography>
        <Typography paragraph>
          So basically DI is everywhere in some form or the other. Then why do
          businesses shy away from having decision intelligence teams? The
          reason is simple, DI has often been seen as a <strong>vitamin</strong>
          , "nice to have but not essential". However, with increasing scale,
          competition, and complexity, optimization is set to become a{" "}
          <strong>painkiller</strong>, "vital for survival and growth".
          Businesses will find it impossible to operate efficiently without it,
          as DI will be crucial in saving time, reducing costs, and increasing
          sales. As we move forward, the adoption of Decision Intelligence will
          likely become talk of the business, transforming it from an optional
          advantage into a must-have tool for success.
        </Typography>

        <Box mt={4}>
          <img
            src={BlogPostImg}
            alt={"Linkedin Content on Decision Intelligence"}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography variant="body2">
          <strong>
            Fig 1. What industry experts have to say about mathematical
            optimization, decision intelligence, AI and operations research
          </strong>
        </Typography>

        <Typography
          variant="h3"
          fontWeight={600}
          gutterBottom
          sx={{ marginTop: 4 }}
        >
          Some good news
        </Typography>

        <Typography paragraph>
          Decision Intelligence is currently being used at a mature stage in a
          few industries namely, Logistics, Supply Chain, Airlines, Energy and
          Manufacturing. But the scope as we can see, is immense with
          innumerable applications for today and the future.
        </Typography>

        <Typography paragraph>
          Join us on this journey to make Decision Intelligence accessible for a
          more efficient world with smarter decisions. Consider this your sign
          to integrate optimization into your business to gain a competitive
          edge. Reach out to us to discover how Decision Intelligence can
          enhance your operations. Stay tuned as we get started and kick things
          off!
        </Typography>
      </Paper>
    </Container>
  );
};

export default BlogContent;
