import React, { useState } from "react";
import LastFrameImage from "../assets/bg_content.png";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContactModal from "./ContactModal";

const Content = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContactUsClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundImage: `url(${LastFrameImage})`,
        backgroundSize: "contain",
        pb: isMobile ? 4 : 8,
        px: isMobile ? 2 : 0,
      }}
    >
      <Typography
        variant={isMobile ? "h1_mobile" : "h1"}
        sx={{ fontWeight: 700, display: "block" }}
      >
        Accelerate Your
      </Typography>
      <Typography
        variant={isMobile ? "h1_mobile" : "h1"}
        sx={{ fontWeight: 700, display: "block" }}
      >
        Decision-Making
      </Typography>
      <Typography
        variant={isMobile ? "h6" : "h4"}
        component="p"
        sx={{ color: "#55555D", pb: isMobile ? 2 : 5, pt: isMobile ? 1 : 2.5 }}
      >
        Put decision intelligence at the heart of your strategic, operational,
        and tactical decision-making
      </Typography>
      <Button
        onClick={handleContactUsClick}
        variant="contained"
        sx={{ color: "white", borderRadius: 2 }}
      >
        Join the Waitlist
      </Button>
      <ContactModal open={modalOpen} onClose={handleCloseModal} />
    </Box>
  );
};

export default Content;
