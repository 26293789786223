import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Optexity_logo from "../assets/logo_optexity.svg";
import ContactModal from "./ContactModal";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleContactUsClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white" }}
      color="default"
      elevation={0}
    >
      <Toolbar
        sx={{ justifyContent: "space-between", mx: isMobile ? 2 : 16, my: 2 }}
      >
        <Button
          component={Link}
          to="/"
          sx={{ padding: 0, minWidth: 0 }} // Remove default padding
        >
          <Box
            component="img"
            alt="Optexity Logo"
            src={Optexity_logo}
            sx={{
              maxHeight: "48px",
              maxWidth: "192px",
            }}
          />
        </Button>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/blogs" onClick={handleMenuClose}>
                Blogs
              </MenuItem>
              <MenuItem component={Link} to="/about" onClick={handleMenuClose}>
                About
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleContactUsClick();
                  handleMenuClose();
                }}
              >
                <Button
                  variant="contained"
                  sx={{ color: "white", borderRadius: 2 }}
                >
                  Contact Us
                </Button>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ display: "flex", gap: 2.5 }}>
            <Button component={Link} to="/blogs" sx={{ color: "black" }}>
              Blogs
            </Button>
            <Button component={Link} to="/about" sx={{ color: "black" }}>
              About
            </Button>
            <Button
              onClick={handleContactUsClick}
              variant="contained"
              sx={{ color: "white", borderRadius: 2 }}
            >
              Contact Us
            </Button>
          </Box>
        )}
        <ContactModal open={modalOpen} onClose={handleCloseModal} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
