import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";
import EVImg from "../assets/ev_img.jpeg";

export const blogsData = [
  {
    id: "welcome-blog",
    title: "Decision Intelligence: The Unsung Hero of Data, Analytics, and AI",
    image: EVImg,
    author: "Shivam Goyal",
    date: "June 07, 2024",
    content: "HEY",
  },
  // {
  //   id: 2,
  //   title: "The Art of Writing Engaging Content",
  //   image: "/images/blog2.jpg",
  //   author: "Jane Smith",
  //   date: "June 3, 2022",
  //   content: "Detailed content of the blog goes here...",
  // },
  // {
  //   id: 3,
  //   title: "How to Grow Your Blog Audience",
  //   image: "/images/blog3.jpg",
  //   author: "Alex Johnson",
  //   date: "July 21, 2022",
  //   content: "Detailed content of the blog goes here...",
  // },
];

const Blogs = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Blogs
      </Typography>
      <Grid container spacing={3}>
        {blogsData.map((blog, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardActionArea component={Link} to={`/blog/${blog.id}`}>
                <CardMedia
                  component="img"
                  height="200"
                  image={blog.image}
                  alt={blog.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {blog.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    By {blog.author} | {blog.date}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Blogs;
