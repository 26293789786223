import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AlokPatelImg from "../assets/alok_patel_img.jpeg";
import AnandSubImg from "../assets/anand_sub_img.jpeg";
import BhaveshImg from "../assets/bhavesh_img.jpeg";
import GoyalImg from "../assets/goyal_img.jpeg";
import BansalImg from "../assets/bansal_img.jpeg";

const teamMembers = [
  {
    name: "Bhavesh Tolia",
    role: "Tech",
    experiences: "IITD 2021",
    image: BhaveshImg,
    linkedin: "https://www.linkedin.com/in/bhavesh-tolia-a17a37164/",
  },
  {
    name: "Shivam Goyal",
    role: "Research and Intelligence",
    experiences: "IIT Delhi 2021",
    image: GoyalImg,
    linkedin: "https://www.linkedin.com/in/shivam-goyal-9b844a17b/",
  },
  {
    name: "Shivam Bansal",
    role: "Product and Strategy",
    experiences: "IITD 2021",
    image: BansalImg,
    linkedin: "https://www.linkedin.com/in/shivam-bansal99/",
  },
  {
    name: "Alok Patel",
    role: "Mentor",
    experiences: "8 years in financial management.",
    image: AlokPatelImg,
    linkedin: "https://www.linkedin.com/in/alok7iitb/",
  },
  {
    name: "Anand Subramanian",
    role: "Advisor",
    experiences: "7 years in marketing and brand management.",
    image: AnandSubImg,
    linkedin: "https://www.linkedin.com/in/anandsubraman/",
  },
];

const About = () => {
  return (
    <Container>
      <Box sx={{ maxWidth: 800, my: 5 }}>
        <Typography variant="h2" gutterBottom sx={{ pb: 3 }}>
          Empowering Informed Decisions, with Accessible Decision Science
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          At our core, we believe decision science should be as accessible as
          data science. While the importance of forecasting is widely
          recognized, optimization—the next logical step—often goes unnoticed.
          Insights and predictions can only take you so far. To truly bridge the
          gap, mathematical models are essential.
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          Our mission is to simplify the building, deployment, testing, and
          explanation of decision models. We aim to equip businesses and
          individuals with the tools they need to make informed, efficient, and
          impactful decisions. Because while data tells the story, decisions
          write the ending.
        </Typography>
        <Typography variant="h5" sx={{ pb: 2, color: "#55555D" }}>
          Join us in transforming the way decisions are made, leveraging the
          power of accessible decision science to drive better outcomes.
        </Typography>
      </Box>

      <Box my={6}>
        <Typography variant="h2" component="h2" gutterBottom>
          Our Team
        </Typography>
        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  maxWidth: 345,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  image={member.image}
                  alt={member.name}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="h3">
                      {member.name}
                    </Typography>
                    <IconButton
                      component="a"
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ ml: 1 }}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="subtitle1" color="textSecondary">
                    {member.role}
                  </Typography>
                  {/* <Typography variant="body2" color="textSecondary">
                    {member.experiences}
                  </Typography> */}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default About;
