import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "IBM Plex Sans, Arial, sans-serif",
    fontSize: 16,
    h1: {
      fontFamily: "IBM Plex Sans Condensed, Arial, sans-serif",
      fontSize: "5rem",
    },
    h1_mobile: {
      fontFamily: "IBM Plex Sans Condensed, Arial, sans-serif",
      fontSize: "2.5rem",
    },
    h2: { fontSize: "2.5rem", fontWeight: 700 },
    h3: { fontSize: "1.75rem" },
    h4: { fontSize: "1.5rem" },
    h5: { fontSize: "1.25rem" },
    h6: { fontSize: "1rem" },
    body1: { fontSize: "1.125rem" },
    body2: { fontSize: "0.875rem" },
    button: { textTransform: "none" }, // Disable uppercase transformation for buttons
  },
  palette: {
    primary: {
      main: "#3d5afe", // Custom primary color
    },
    secondary: {
      main: "#ffffff", // Custom secondary color
    },
  },
});

export default theme;
